import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img, { FluidObject } from 'gatsby-image';

import { Podcast } from '../types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import '../styles/episode.scss';
import PodcastCardFull from '../components/PodcastCardFull';

interface Props {
  data: {
    allContentfulAsset: {
      edges: {
        node: {
          fluid: FluidObject;
        };
      }[];
    };
    contentfulPodcast: Podcast;
  };
}

const Episode: FunctionComponent<Props> = ({ data }: Props) => {
  const options = {
    renderNode: {
      'embedded-asset-block': (node: any) => {
        for (let i = 0; i < data.allContentfulAsset.edges.length; i += 1) {
          if (data.allContentfulAsset.edges[i].node.fluid.src.startsWith(node.data.target.fields.file['en-US'].url)) {
            return (
              <Img
                className="episode-content__image"
                fluid={data.allContentfulAsset.edges[i].node.fluid}
              />
            );
          }
        }

        return null;
      },
    },
  };

  return (
    <Layout invertNav>
      <SEO title={`Ever Forward Radio - ${data.contentfulPodcast.slug}`} />

      <div>
        <div className="episode-header-background">
          <div className="container">
            <div className="episode-quote">
              <p>{data.contentfulPodcast.quote || ''}</p>

              <h2>{data.contentfulPodcast.quoteSource || ''}</h2>
            </div>

            <PodcastCardFull
              activePodcastId={data.contentfulPodcast.id}
              podcast={data.contentfulPodcast}
              onPodcastPlayStateChanged={() => null}
            />
          </div>
        </div>

        <div className="container">
          {data.contentfulPodcast.videoUrl ? (
            <div className="video-container video-container--padded">
              <div className="iframe-wrapper">
                <iframe
                  title="Ever Forward Radio"
                  width="560"
                  height="315"
                  src={data.contentfulPodcast.videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          ) : null}

          <section className="episode-content">
            <h1 className="section-header text-center">{data.contentfulPodcast.title}</h1>

            {data.contentfulPodcast.content ? (
              documentToReactComponents(data.contentfulPodcast.content.json, options)
            ) : null}

            {data.contentfulPodcast.transcript ? (
              <>
                <h2>Transcript</h2>

                <div className="transcript">
                  {documentToReactComponents(data.contentfulPodcast.transcript.json, options)}
                </div>
              </>
            ) : null}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const data = graphql`
  query($embeddedImages: [String!], $slug: String!) {
    allContentfulAsset(filter: { file: { url: { in: $embeddedImages }}}) {
      edges {
        node {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    contentfulPodcast(slug: { eq: $slug }) {
      audioUrl
      content {
        json
      }
      id
      primaryImage {
        fluid(maxWidth: 200) {
          ...GatsbyContentfulFluid
        }
      }
      publishedDate
      quote
      quoteSource
      slug
      title
      transcript {
        json
      }
      videoUrl
    }
  }
`;

export default Episode;
